
export const FAQ = `
# FAQ

#### What is this?

This is a demo for [SURE.social](https://sure.social). SURE.social is a privacy-preserving online identity service that supports anonymous "ad hoc" age verification.

#### How does it work?

If you are a website operator, you can generate a random "challenge string" for each verification session. If your visitor has a SURE.social account, they can obfuscate that string by hashing it with a blinding factor before sending it to SURE.social. SURE.social will verify the user's age, sign the obfuscated string with a private key and return the signature along with the name of the corresponding public key. You can then retrieve the public key from SURE.social, check the signature and grant your visitor access.

No information that could identify your website or associate it with a user is sent to SURE.social's server and no information that could identity or track a user is given to you.

You can find sourcecode and more detailed instructions [here](https://github.com/sure-social/sure-samples-js). 

#### How does SURE.social verify a user's age?

SURE.social uses a mix of in-house manual review and third-party KYC vendors such as Stripe and Veriff.

#### Do I have to go through KYC to create a SURE.social account?

No. You can use an unverified SURE.social account to create "pretend" age claims for testing purposes.

#### Is SURE.social free for websites?

Yes. If you have a website and need to verify the age of your visitors without compromising their privacy, then SURE.social is free for you to integrate and use.

#### Who pays for SURE.social?

Privacy-minded individuals who don't want their PII all over the internet, don't want to be tracked, and want to skip the KYC line.

#### Will SURE.social solve my age-verification compliance needs?

We don't know. Read the [ToS](https://sure.social/tos) and ask your lawyers.

#### Is there a blockchain?

No.
`.trim ();
